<template>
  <div>
    <window-header></window-header>
    <nav style="justify-content: space-between;">
      <button class="button button-fill" :class="{ disabled: activeTab == 0 }" @click="$refs['main-tabs'].prevTab()">
        <fa-icon :icon="['fas', 'chevron-left']" />
        <span>{{ $t('back') }}</span>
      </button>
      <button class="button button-fill" @click="$refs['main-tabs'].nextTab()" v-if="activeTab < 1"
        :disabled="!formData.origen || !formData.destino">
        <span>{{ $t('next') }}</span>
        <fa-icon :icon="['fas', 'chevron-right']" />
      </button>
      <button v-if="activeTab == 1 && articulosSelected().length" class="button button-fill" @click="finalizar">
        <span>Guardar</span>
      </button>
    </nav>
    <section>
      <article style="height:395px;">
        <r-tabs ref="main-tabs" style="top:0px; left:0px; height:395px;" :buttons="[
          '1. Selección/Acotación',
          '2. Confirmación'
        ]" :styleButton="'width:50%;'" @active="onActiveTab">
          <div class="tab">
            <field name="origen" widget="select" searchable label="Origen" width="300px" style="top:10px; left:5px;"
              :options="{ 'P. Coste U. Venta': 'coste_unidad_venta', 'Coste Hechuras (Divisa)': 'coste_hechuras_divisa', 'Hechuras (€)': 'hechuras_euros', 'Coste Según Escandallo': 'coste_segun_escandallo', 'Coste Para Escandallo': 'coste_para_escandallo', 'Tarifa 1': 'tarifa1', 'Tarifa 2': 'tarifa2', 'Tarifa 3': 'tarifa3', 'Tarifa 4': 'tarifa4', 'Tarifa 5': 'tarifa5', 'Tarifa 6': 'tarifa6', 'Tarifa 7': 'tarifa7', 'Tarifa 8': 'tarifa8', 'Tarifa 9': 'tarifa9', 'Tarifa 10': 'tarifa10', 'P. Venta Web': 'pventa_web', 'Coste Según Escandallo Modelo': 'coste_segun_escandallo_modelo', 'Peso Aleación': 'peso_aleacion', 'Peso Fino': 'peso_fino', 'Peso Unitario': 'peso_unitario', 'Peso Aleación + Merma': 'peso_aleacion_merma', 'Peso Fino + Merma': 'peso_fino_merma', 'Peso Unitario + Merma': 'peso_unitario_merma' }" />
            <field name="destino" widget="select" searchable label="Destino" width="300px" style="top:10px; left:315px;"
              :options="{ 'Tarifa 1': 'tarifa1', 'Tarifa 2': 'tarifa2', 'Tarifa 3': 'tarifa3', 'Tarifa 4': 'tarifa4', 'Tarifa 5': 'tarifa5', 'Tarifa 6': 'tarifa6', 'Tarifa 7': 'tarifa7', 'Tarifa 8': 'tarifa8', 'Tarifa 9': 'tarifa9', 'Tarifa 10': 'tarifa10', 'P. Venta Web': 'pventa_web', 'Coste Para Escandallo': 'coste_para_escandallo' }" />
            <filtro-seleccion-acotacion style="left: 5px; top: 50px;" ref="selaco" referencia="recalculo_tarifa"
              model="articulo" nombre="Recálculo tarifa" />
            <fieldset style="position: absolute; left: 5px; bottom: 5px; height: 62px; width: 500px">
              <legend>Recálculo</legend>
              <div>
                Tarifa Destino = Tarifa Origen * ( 1 +
                <field name="porcentaje" widget="float" type="number" default="0" dec="3" width="70px"
                  style="position: relative;display: inline-block;" />% ) +
                <field name="incremento_lineal" widget="float" type="number" default="0" dec="3" width="80px"
                  style="position: relative;display: inline-block;" />
              </div>
            </fieldset>
            <fieldset style="position: absolute; left: 515px; bottom: 5px; height: 62px; width: 138px">
              <legend>Redondeo</legend>
              <field name="redondeo" widget="float" default="0.001" placeholder="0.001" dec="3" width="100px"
                style="top:26px; left:17px;" />
            </fieldset>
          </div>
          <div class="tab">
            <field ref="articulos" name="articulos" widget="handsontable" :height="300" :minRows="15"
              style="top:10px; left:4px; width: 648px;" :columns="[
                { name: 'sel', header: 'Sel.', widget: 'checkbox', help: 'Seleccionado' },
                { name: 'ean13', header: '||||||||', readOnly: true },
                { name: 'codigo', header: 'Código', readOnly: true },
                { name: 'nombre', header: 'Descripción', readOnly: true },
                { name: 'origen', header: 'Origen', type: 'numeric', numericFormat: { pattern: '0,0.00' }, readOnly: true },
                { name: 'destino', header: 'Destino', type: 'numeric', numericFormat: { pattern: '0,0.00' } },
              ]" />
          </div>
        </r-tabs>
      </article>
    </section>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import FiltroSeleccionAcotacion from "./../components/FiltroSeleccionAcotacion.vue";
var floatOrZero = function (val) {
  return isFinite(parseFloat(val || 0.0)) ? parseFloat(val || 0.0) : 0.0;
};
export default {
  mixins: [WindowMixin, rFormMixin],
  components: { FiltroSeleccionAcotacion },
  data: function () {
    return {
      title: "Recálculo de tarifas de precios",
      dbAdapter: "articulo",
      primary: "codigo",
      mode: "new",
      activeTab: 0
    };
  },
  methods: {
    finalizar() {
      var self = this;
      self.app.confirm(
        "Los cambios son irreversibles ¿Estas seguro?",
        function () {
          var p = Promise.resolve();
          self.formData.articulos.forEach(function (articulo) {
            if (parseInt(articulo.sel)) {
              let data = {};
              data[self.formData.destino] = articulo.destino;
              p = p.then(
                window.DB.put("articulo", {
                  itemId: articulo.codigo,
                  data
                })
              );
            }
          });
          p.then(function () {
            self.app.toast("Datos actualizados", "success");
          }).catch(function (e) {
            self.app.toast("Error al actualizar los datos", "error");
            window.console.log("Error: " + e);
          });
        }
      );
    },
    onActiveTab(i) {
      var self = this;
      self.activeTab = i;
      switch (i) {
        case 1:
          var incremento_lineal = parseFloat(self.formData.incremento_lineal || 0);
          var porcentaje = parseFloat(self.formData.porcentaje || 0);
          window.DB.getList("articulo", {
            filter: self.$refs.selaco.getFilter(),
            fields: ["ean13", "codigo", "nombre", self.formData.origen]
          })
            .then(function (res) {
              res.data.forEach(function (item, i) {
                let origen = res.data[i][self.formData.origen] || 0;
                res.data[i].origen = origen;
                res.data[i].destino = origen * (1 + parseFloat(porcentaje) / 100) + parseFloat(incremento_lineal);
              });
              self.$set(self.formData, "articulos", res.data);
            })
            .catch(function (res) {
              window.console.log(res);
            });
      }
    },
    articulosSelected() {
      var self = this;
      return self.formData.articulos
        ? self.formData.articulos.filter(doc => parseInt(doc.sel))
        : [];
    }
  }
};
</script>
